import React, { useState, useEffect } from 'react';
import
{
  Avatar, Button, Comment, Form, Input, List, Popover, Modal, Select, Tooltip,
}
from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../common/components/Loader';
import {
  getCookie, getFormattedDate, getFormattedTime, setAnalytics,
} from '../../../utils';
import {
  getCommentsAction,
  createCommentAction,
  createCommentResetAction,
  getCommentsResetAction,
  deleteCommentAction,
  deleteCommentResetAction,
  // resolveCommentAction,
  resolveCommentResetAction,
  updateCommentAction,
  updateCommentResetAction,
  getCommentsCountsAction,
  getCommentsCountsResetAction,
  createCommentForApprovalAction,
  createCommentForApprovalResetAction,
  getCommentsForApprovalAction,
  getCommentsForApprovalResetAction,
  deleteCommentForApprovalAction,
  deleteCommentForApprovalResetAction,
  updateCommentForApprovalAction,
  updateCommentForApprovalResetAction,
} from './logic';
import { ListUsersAction } from '../ShareProjectModal/logic';

const { TextArea } = Input;
const { Option } = Select;

const apiParamsMapping = {
  getApi: {
    projects: ['project_id'],
    analysis: ['project_id', 'analysis_id'],
    queries: ['project_id', 'analysis_id', 'query_id'],
    site: ['project_id', 'analysis_id', 'query_id', 'site_id'],
    pi: ['project_id', 'analysis_id', 'query_id', 'pi_id'],
    list_pi: ['pi_id'],
    list_site: ['site_id'],
    request_site: ['innoplexus_id'],
    request_pi: ['innoplexus_id'],
  },
  createApi: {
    projects: ['project_id'],
    analysis: ['project_id', 'analysis_id'],
    queries: ['project_id', 'analysis_id', 'query_id'],
    site: ['project_id', 'analysis_id', 'query_id', 'site_id'],
    pi: ['project_id', 'analysis_id', 'query_id', 'pi_id'],
    list_pi: ['pi_id'],
    list_site: ['site_id'],
    request_site: ['innoplexus_id'],
    request_pi: ['innoplexus_id'],
  },
  getCountApi: {
    analysis: ['project_id'],
    queries: ['project_id', 'analysis_id'],
    site: ['project_id', 'analysis_id'],
  },
};

const propParamKeyMapping = {
  project_id: 'projectId',
  query_id: 'queryId',
  analysis_id: 'analysisId',
  // id: 'piId',
  pi_id: 'piId',
  site_id: 'siteId',
  innoplexus_id: 'innoplexusId',
};

const getCommentParams = (apitype, commentType, props) => {
  const obj = {};
  apiParamsMapping[apitype][commentType].map((itm) => {
    if (itm === 'pi_id' && props.from === 'allPi') {
      obj[itm] = props.piIdList.join(',')
    } else {
      obj[itm] = props[propParamKeyMapping[itm]];
    }
    return null;
  })
  return obj;
}

const getCommentType = (propType, specifictype) => {
  if (!specifictype) {
    return propType;
  }
  if (specifictype === 'specific' && (propType === 'pi' || propType === 'list_pi')) {
    return 'pi';
  }
  if (specifictype === 'specific' && (propType === 'site' || propType === 'list_site')) {
    return 'site';
  }
  if (specifictype === 'generic' && (propType === 'pi' || propType === 'list_pi')) {
    return 'list_pi';
  }
  if (specifictype === 'generic' && (propType === 'site' || propType === 'list_site')) {
    return 'list_site';
  }
  return propType;
}

const CommentList = (props) => {
  const dispatch = useDispatch();
  const [replyCommentId, setReplyCommentId] = useState('')
  const [submittingReply, setSubmittingReply] = useState(false);
  const [submittingEditedCmt, setSubmittingEditedCmt] = useState(false);
  const [replyValue, setReplyValue] = useState('');
  const [editCmtValue, setEditCmtValue] = useState('');
  const [commentForDeletion, setCommentForDeletion] = useState({});
  const [editedComment, setEditedComment] = useState({});
  const [isDeleteCommentModalVisible, setIsDeleteCommentModalVisible] = useState(false);
  const [sitePIExtraType, setSitePIExtraType] = useState('');

  const deleteCommentResponse = useSelector((state) => state.deleteCommentResponse);
  const updateCommentResponse = useSelector((state) => state.updateCommentResponse);
  const deleteCommentForApprovalResponse = useSelector((state) => state.deleteCommentForApprovalResponse);
  const updateCommentForApprovalResponse = useSelector((state) => state.updateCommentForApprovalResponse);

  useEffect(() => {
    setSitePIExtraType(props.sitePIExtraType);
  }, [props.sitePIExtraType])

  useEffect(() => {
    if (deleteCommentResponse.flag) {
      setIsDeleteCommentModalVisible(false);
      setCommentForDeletion({});
      dispatch(deleteCommentResetAction());
      dispatch(getCommentsAction({
        type: getCommentType(props.type, sitePIExtraType),
        ...getCommentParams('getApi', getCommentType(props.type, sitePIExtraType), props),
      }));
    }
  }, [JSON.stringify(deleteCommentResponse)])

  useEffect(() => {
    if (updateCommentResponse.flag) {
      setEditCmtValue('');
      setEditedComment({});
      setSubmittingEditedCmt(false);
      props.setIsCommentEdited(false)
      dispatch(updateCommentResetAction());
      dispatch(getCommentsAction({
        type: getCommentType(props.type, sitePIExtraType),
        ...getCommentParams('getApi', getCommentType(props.type, sitePIExtraType), props),
      }));
    }
  }, [JSON.stringify(updateCommentResponse)])

  useEffect(() => {
    if (deleteCommentForApprovalResponse.flag) {
      setIsDeleteCommentModalVisible(false);
      setCommentForDeletion({});
      dispatch(deleteCommentForApprovalResetAction());
      props.setIsPopoverVisible(false)
      dispatch(getCommentsForApprovalAction({
        request_for: props.type === 'request_site' ? 'SITE' : 'PI',
        ...getCommentParams('getApi', getCommentType(props.type, props.sitePIExtraType), props),
      }));
    }
  }, [JSON.stringify(deleteCommentForApprovalResponse)])

  useEffect(() => {
    if (updateCommentForApprovalResponse.flag) {
      setEditCmtValue('');
      setEditedComment({});
      setSubmittingEditedCmt(false);
      dispatch(updateCommentForApprovalResetAction());
      dispatch(getCommentsForApprovalAction({
        request_for: props.type === 'request_site' ? 'SITE' : 'PI',
        ...getCommentParams('getApi', getCommentType(props.type, props.sitePIExtraType), props),
      }));
    }
  }, [JSON.stringify(updateCommentForApprovalResponse)])

  const handleReplySubmit = (parent_id) => {
    if (!replyValue) {
      return;
    }
    setSubmittingReply(true);
    setReplyCommentId('')
    props.onNewCommentAdd();
    if (props.type === 'request_site' || props.type === 'request_pi') {
      dispatch(createCommentForApprovalAction({
        request_for: props.type === 'request_site' ? 'SITE' : 'PI',
        action_type: props.action_type,
        comment: replyValue,
        parent_id,
        ...getCommentParams('createApi', getCommentType(props.type, sitePIExtraType), props),
      }));
    } else {
      dispatch(createCommentAction({
        type: getCommentType(props.type, sitePIExtraType),
        comment: replyValue,
        is_lcor: props.is_lcor,
        parent_id,
        ...getCommentParams('createApi', getCommentType(props.type, sitePIExtraType), props),
      }));
    }
  };

  const handleEditCmtSubmit = (cmtItm) => {
    if (!editCmtValue) {
      return;
    }
    setSubmittingEditedCmt(true);
    const tagged_ids = []
    props.taggedUserList.map((user) => {
      if (editCmtValue.includes(`@${user.name}`)) {
        tagged_ids.push(user.id)
      }
      return null
    })
    if (props.type === 'request_site' || props.type === 'request_pi') {
      dispatch(updateCommentForApprovalAction({
        body: {
          comment: editCmtValue,
          tagged_ids,
        },
        params: {
          innoplexus_id: cmtItm.innoplexus_id,
        },
      }))
    } else {
      dispatch(updateCommentAction({
        body: {
          comment: editCmtValue,
          tagged_ids,
        },
        params: {
          innoplexus_id: cmtItm.innoplexus_id,
        },
      }))
    }
  };

  const handleReplyChange = (e, commentId) => {
    if (replyCommentId === '') {
      setReplyCommentId(commentId)
    } else if (replyCommentId !== commentId) {
      setReplyCommentId(commentId)
      setReplyValue('')
    }
    if (e.target.value) {
      setReplyValue(e.target.value.trimStart());
    } else {
      setReplyValue('');
    }
  };

  const handleReplyCancel = () => {
    setReplyValue('');
  };

  const handleEditCmtChange = (e) => {
    if (e.target.value) {
      if (e.target.value.includes('@')) {
        setEditCmtValue(e.target.value.trimStart());
        props.setIsUserListOpen(true)
        props.setSearchText(e.target.value.substring(e.target.value.lastIndexOf('@') + 1, e.target.value.length))
      } else {
        props.setIsUserListOpen(false)
        props.setSearchText('')
        setEditCmtValue(e.target.value.trimStart());
      }
    } else {
      setEditCmtValue('');
      props.setIsUserListOpen(false)
    }
  };

  const handleEditCmtCancel = () => {
    setEditCmtValue('');
    props.setIsCommentEdited(false)
    props.setIsUserListOpen(false)
    props.setTaggedUserList([])
    setEditedComment({});
  };

  const selectCommentForDeletion = (cmtItm) => {
    setCommentForDeletion(cmtItm);
    setIsDeleteCommentModalVisible(true);
  }

  const selectCommentForEditing = (cmtItm) => {
    if (!cmtItm.isresolved) {
      setEditedComment(cmtItm);
      props.setIsCommentEdited(true)
      setEditCmtValue(cmtItm.commentText);
    }
  }

  const handleDeleteCancel = () => {
    setCommentForDeletion({});
    setIsDeleteCommentModalVisible(false);
  }

  const onDeleteCommentConfirm = () => {
    if (props.type === 'request_site' || props.type === 'request_pi') {
      dispatch(deleteCommentForApprovalAction({
        params: {
          innoplexus_id: commentForDeletion.innoplexus_id,
        },
        body: {},
      }))
    } else {
      const params = {
        action: 'comment deleted',
        commentData: JSON.stringify(commentForDeletion),
      }
      setAnalytics('deleteComment', 'data', params)
      dispatch(deleteCommentAction({
        params: {
          innoplexus_id: commentForDeletion.innoplexus_id,
        },
        body: {},
      }))
    }
  }

  const renderChildern = (cmtItm) => {
    return cmtItm.children.map((child, index) => {
      if (editedComment.comment_id === child.comment_id) {
        return (
          <Comment
            avatar={
              (
                <Avatar
                  style={{
                    backgroundColor: '#8A0050',
                  }}
                >
                  {editedComment.author[0]}
                </Avatar>
              )
            }
            content={
              (
                <EditEditor
                  onChange={handleEditCmtChange}
                  onSubmit={() => handleEditCmtSubmit(child)}
                  submitting={submittingEditedCmt}
                  value={editCmtValue}
                  setEditValue={setEditCmtValue}
                  onCancel={handleEditCmtCancel}
                  isUserListOpen={props.isUserListOpen}
                  setIsUserListOpen={props.setIsUserListOpen}
                  taggedUserList={props.taggedUserList}
                  setTaggedUserList={props.setTaggedUserList}
                  searchText={props.searchText}
                  setSearchText={props.setSearchText}
                  isUpdateChild
                />
              )
            }
          />
        );
      }
      return (
        <Comment
          actions={child.user_id === getCookie('userName')
            ? [
              <div role="presentation" onClick={() => selectCommentForDeletion(child)}>
                <div className="comment-action delete-icon" title="Delete Comment" />
              </div>,
              <div role="presentation" className={cmtItm.isresolved ? 'disabled' : ''} onClick={!cmtItm.isresolved ? () => selectCommentForEditing(child) : null}>
                <div className="comment-action edit-icon" title="Edit Comment" />
              </div>,
            ]
            : []}
          {...child}
          key={index}
        />
      )
    });
  }

  return (
    <>
      <List
        dataSource={props.comments}
        // header={`${props.comments.length} ${props.comments.length > 1 ? 'replies' : 'reply'}`}
        itemLayout="horizontal"
        renderItem={(cmtItm) => {
          if (editedComment.comment_id === cmtItm.comment_id) {
            return (
              <Comment
                avatar={
                  (
                    <Avatar
                      style={{
                        backgroundColor: '#8A0050',
                      }}
                    >
                      {editedComment.author[0]}
                    </Avatar>
                  )
                }
                content={
                  (
                    <EditEditor
                      onChange={handleEditCmtChange}
                      onSubmit={() => handleEditCmtSubmit(cmtItm)}
                      submitting={submittingEditedCmt}
                      value={editCmtValue}
                      setEditValue={setEditCmtValue}
                      onCancel={handleEditCmtCancel}
                      isUserListOpen={props.isUserListOpen}
                      setIsUserListOpen={props.setIsUserListOpen}
                      taggedUserList={props.taggedUserList}
                      setTaggedUserList={props.setTaggedUserList}
                      searchText={props.searchText}
                      setSearchText={props.setSearchText}
                      isUpdate
                    />
                  )
                }
              >
                {cmtItm.children?.length
                  ? renderChildern(cmtItm)
                  : null}
                {!cmtItm.isresolved && !props.isCommentEdited
                  ? (
                    <Comment
                      avatar={
                        (
                          <Avatar
                            style={{
                              backgroundColor: '#8A0050',
                            }}
                          >
                            {getCookie('name')[0]}
                          </Avatar>
                        )
                      }
                      content={
                        (
                          <Editor
                            onChange={(e) => handleReplyChange(e, cmtItm.comment_id)}
                            onSubmit={() => handleReplySubmit(cmtItm.comment_id)}
                            submitting={submittingReply}
                            value={replyCommentId === cmtItm.comment_id ? replyValue : ''}
                            isReply
                            onCancel={handleReplyCancel}
                          />
                        )
                      }
                    />
                  )
                  : null}
              </Comment>
            )
          }
          return (
            <Comment
              className={cmtItm.isresolved ? 'resolved-cmt' : null}
              actions={cmtItm.user_id === getCookie('userName') && (props.type !== 'request_pi' && props.type !== 'request_site')
                ? [
                  <div role="presentation" onClick={() => selectCommentForDeletion(cmtItm)}>
                    <div className="comment-action delete-icon" title="Delete Comment" />
                  </div>,
                  <div role="presentation" className={cmtItm.isresolved ? 'disabled' : ''} onClick={() => selectCommentForEditing(cmtItm)}>
                    <div className="comment-action edit-icon" title="Edit Comment" />
                  </div>,
                  // <div role="presentation" className={cmtItm.isresolved ? 'disabled' : ''} onClick={() => resolveComment(cmtItm)}>
                  //   <div className="comment-action resolve-icon" title={cmtItm.isresolved ? 'Resolved comment thread' : 'Resolve Comment Thread'} />
                  // </div>,
                ]
                : cmtItm.user_id === getCookie('userName') && (props.type === 'request_pi' || props.type === 'request_site')
                  ? [
                    <div role="presentation" onClick={() => selectCommentForDeletion(cmtItm)}>
                      <div className="comment-action delete-icon" title="Delete Comment" />
                    </div>,
                    <div role="presentation" className={cmtItm.isresolved ? 'disabled' : ''} onClick={() => selectCommentForEditing(cmtItm)}>
                      <div className="comment-action edit-icon" title="Edit Comment" />
                    </div>,
                  ]
                  : []}
              {...cmtItm}
            >
              {
                // cmtItm.children.length
                // ? renderChildern(cmtItm)
                // : null
              }
              {
                // !cmtItm.isresolved
                // ? (
                //   <Comment
                //     avatar={
                //       (
                //         <Avatar
                //           style={{
                //             backgroundColor: '#8A0050',
                //           }}
                //         >
                //           {getCookie('name')[0]}
                //         </Avatar>
                //       )
                //     }
                //     content={
                //       (
                //         <Editor
                //           onChange={(e) => handleReplyChange(e, cmtItm.comment_id)}
                //           onSubmit={() => handleReplySubmit(cmtItm.comment_id)}
                //           submitting={submittingReply}
                //           value={replyCommentId === cmtItm.comment_id ? replyValue : ''}
                //           isReply
                //           onCancel={handleReplyCancel}
                //         />
                //       )
                //     }
                //   />
                // )
                // : null
              }
            </Comment>
          )
        }}
      />
      <Modal
        style={{ marginTop: '81px', width: '100%' }}
        title="Delete Comment?"
        open={isDeleteCommentModalVisible}
        className="del-modal-wrapper"
        onCancel={handleDeleteCancel}
        destroyOnClose
        footer={null}
      >
        <Loader loading={deleteCommentResponse.loading || deleteCommentForApprovalResponse.loading} error={deleteCommentResponse.error || deleteCommentForApprovalResponse.error}>
          <div className="delete-modal-card">
            Are you sure you want to delete the comment?
          </div>
          <div className="footer-btn">
            <Button
              className="delete-button"
              type="primary"
              onClick={() => {
                onDeleteCommentConfirm()
              }}
            >Yes
            </Button>
          </div>
        </Loader>
      </Modal>
    </>
  );
}

const EditEditor = (
  {
    onChange, onSubmit, submitting, value, isReply, onCancel, isUpdate, isUpdateChild, isUserListOpen, searchText, setIsUserListOpen, setEditValue, setTaggedUserList, taggedUserList, setSearchText,
  },
) => {
  const usersList = useSelector((store) => store.usersList);

  const handleTagedUser = (user) => {
    setTaggedUserList([...taggedUserList, user])
    const substr = value.substring(0, value.lastIndexOf('@'))
    setEditValue(`${substr}@${user.name}`)
    setIsUserListOpen(false)
    setSearchText('')
  }
  return (
    <>
      <Form.Item>
        <div className="no-data">You can use @ to mention users in your comment.</div>
        <TextArea
          rows={2}
          onChange={onChange}
          value={value}
          placeholder={(isReply || isUpdateChild) ? 'Reply' : 'Add your comment here'}
        />
        {isUserListOpen
          ? (
            <div className="option-dropdown" style={{ display: 'flex', flexDirection: 'column' }}>
              { usersList?.data?.data?.users.map((item) => (
                searchText === '' ? (
                  <div className="option-dropdown-itm" role="presentation" onClick={() => handleTagedUser(item)}>
                    {item.name}
                  </div>
                ) : item.name.toLowerCase().includes(searchText.toLowerCase()) ? (
                  <div className="option-dropdown-itm" role="presentation" onClick={() => handleTagedUser(item)}>
                    {item.name}
                  </div>
                ) : null
              ))}
            </div>
          )
          : null}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          className="border-text-btn cancel-btn"
          htmlType="cancel"
          onClick={onCancel}
        >
          Clear
        </Button>
        <Button
          className="border-text-btn"
          htmlType="submit"
          loading={submitting}
          onClick={onSubmit}
          type="primary"
          disabled={!value}
        >
          {isReply ? 'Reply' : (isUpdate || isUpdateChild) ? 'Update Comment' : 'Add Comment'}
        </Button>
      </Form.Item>
    </>
  )
};

const Editor = (
  {
    onChange, onSubmit, submitting, value, isReply, onCancel, isUpdate, isUpdateChild, isUserListOpen, searchText, setIsUserListOpen, setValue, setTaggedUserList, taggedUserList, setSearchText,
  },
) => {
  const usersList = useSelector((store) => store.usersList);

  const handleTagedUser = (user) => {
    setTaggedUserList([...taggedUserList, user])
    const substr = value.substring(0, value.lastIndexOf('@'))
    setValue(`${substr}@${user.name}`)
    setIsUserListOpen(false)
    setSearchText('')
  }
  return (
    <>
      <Form.Item>
        <div className="no-data">You can use @ to mention users in your comment.</div>
        <TextArea
          rows={2}
          onChange={onChange}
          value={value}
          placeholder={(isReply || isUpdateChild) ? 'Reply' : 'Add your comment here'}
        />
        {isUserListOpen
          ? (
            <div className="option-dropdown" style={{ display: 'flex', flexDirection: 'column' }}>
              { usersList?.data?.data?.users.map((item) => (
                searchText === '' ? (
                  <div className="option-dropdown-itm" role="presentation" onClick={() => handleTagedUser(item)}>
                    {item.name}
                  </div>
                ) : item.name.toLowerCase().includes(searchText.toLowerCase()) ? (
                  <div className="option-dropdown-itm" role="presentation" onClick={() => handleTagedUser(item)}>
                    {item.name}
                  </div>
                ) : null
              ))}
            </div>
          )
          : null}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          className="border-text-btn cancel-btn"
          htmlType="cancel"
          onClick={onCancel}
        >
          Clear
        </Button>
        <Button
          className="border-text-btn"
          htmlType="submit"
          loading={submitting}
          onClick={onSubmit}
          type="primary"
          disabled={!value}
        >
          {isReply ? 'Reply' : (isUpdate || isUpdateChild) ? 'Update Comment' : 'Add Comment'}
        </Button>
      </Form.Item>
    </>
  )
};

const CommentEditor = (props) => {
  const dispatch = useDispatch();
  const commentsData = useSelector((state) => state.commentsData);
  const commentsForApprovalData = useSelector((state) => state.commentsForApprovalData);
  const createCommentResponse = useSelector((state) => state.createCommentResponse);
  const createCommentForApprovalResponse = useSelector((state) => state.createCommentForApprovalResponse);
  const resolveCommentResponse = useSelector((state) => state.resolveCommentResponse);
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [isUserListOpen, setIsUserListOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sitePIExtraType, setSitePIExtraType] = useState('');
  const [taggedUserList, setTaggedUserList] = useState([])
  const [isCommentEdited, setIsCommentEdited] = useState(false)

  useEffect(() => {
    if (props.resetComments) {
      setComments([]);
      setValue('');
    }
  }, [JSON.stringify(props)]);

  useEffect(() => {
    if (createCommentResponse.flag && createCommentResponse.data && createCommentResponse.data.data && createCommentResponse.data?.data[0]) {
      const params = {
        action: 'comment posted',
        commentData: JSON.stringify(createCommentResponse.data),
      }
      setAnalytics('commentPosted', 'data', params)
      if (props.from === 'allPi') {
        props.setIsPopoverVisible(false)
      } else {
        dispatch(getCommentsAction({
          type: getCommentType(props.type, sitePIExtraType),
          ...getCommentParams('getApi', getCommentType(props.type, sitePIExtraType), props),
        }));
      }
      if (props?.isCommentNeeded) {
        props.setIsCommentNeeded({
          isNeeded: false,
          isProvied: true,
        })
      }
    }
  }, [JSON.stringify(createCommentResponse)]);

  useEffect(() => {
    if (createCommentForApprovalResponse.flag && createCommentForApprovalResponse.data && createCommentForApprovalResponse.data.data && createCommentForApprovalResponse.data.data.comment_id) {
      dispatch(getCommentsForApprovalAction({
        request_for: props.type === 'request_site' ? 'SITE' : 'PI',
        ...getCommentParams('getApi', getCommentType(props.type, props.sitePIExtraType), props),
      }));
    }
  }, [JSON.stringify(createCommentForApprovalResponse)]);

  useEffect(() => {
    if (resolveCommentResponse.flag) {
      dispatch(getCommentsAction({
        type: getCommentType(props.type, sitePIExtraType),
        ...getCommentParams('getApi', getCommentType(props.type, sitePIExtraType), props),
      }));
      dispatch(resolveCommentResetAction());
    }
  }, [JSON.stringify(resolveCommentResponse)]);

  useEffect(() => {
    setSitePIExtraType(props.sitePIExtraType);
  }, [props.sitePIExtraType])

  useEffect(() => {
    if (commentsData.flag) {
      if (commentsData.data.data && commentsData.data.data?.length) {
        const commentsArr = [];
        commentsData.data.data.map((commentItm) => {
          commentsArr.push({
            author: commentItm.user_name,
            avatar: (
              <Avatar
                style={{
                  backgroundColor: '#8A0050',
                }}
              >
                {commentItm?.user_name && commentItm?.user_name.length ? commentItm?.user_name[0] : []}
              </Avatar>
            ),
            user_id: commentItm.user_id,
            content: <p>{commentItm.tagged_user_names ? ReactHtmlParser(getContent(commentItm)) : commentItm.comment}</p>,
            commentText: commentItm.comment,
            datetime: `${getFormattedDate(commentItm.modification_activity.last_modified_on)} ${getFormattedTime(commentItm.modification_activity.last_modified_on)}`,
            comment_id: commentItm.id,
            isresolved: commentItm.is_resolved,
            innoplexus_id: commentItm.innoplexus_id,
          })
          return null
        })
        setComments(commentsArr);
        setSubmitting(false);
        setValue('');
        dispatch(createCommentResetAction());
        dispatch(createCommentForApprovalResetAction());
      } else {
        setComments([]);
      }
    }
  }, [JSON.stringify(commentsData)]);

  useEffect(() => {
    if (commentsForApprovalData.flag) {
      if (commentsForApprovalData.data.data && commentsForApprovalData.data.data?.length) {
        const commentsArr = [];
        commentsForApprovalData.data.data.map((commentItm) => {
          commentsArr.push({
            author: commentItm.user_name,
            avatar: (
              <Avatar
                style={{
                  backgroundColor: '#8A0050',
                }}
              >
                {commentItm?.user_name[0]}
              </Avatar>
            ),
            user_id: commentItm.user_id,
            content: <p>{commentItm.comment}</p>,
            commentText: commentItm.comment,
            datetime: `${getFormattedDate(commentItm.created_on * 1000)} ${getFormattedTime(commentItm.created_on * 1000)}`,
            comment_id: commentItm.id,
            isresolved: commentItm.is_resolved,
            innoplexus_id: commentItm.innoplexus_id,
          })
          return null;
        })
        setComments(commentsArr);
        setSubmitting(false);
        setValue('');
        dispatch(createCommentResetAction());
        dispatch(createCommentForApprovalResetAction());
      } else {
        setComments([]);
      }
    }
  }, [JSON.stringify(commentsForApprovalData)]);

  const handleSitePIExtraTypeChange = (dropdownvalue) => {
    if (sitePIExtraType !== dropdownvalue) {
      setSitePIExtraType(dropdownvalue);

      dispatch(getCommentsAction({
        type: getCommentType(props.type, dropdownvalue),
        ...getCommentParams('getApi', getCommentType(props.type, dropdownvalue), props),
      }));
    }
  }

  const getContent = (itm) => {
    if (itm.tagged_user_names) {
      let txt = itm.comment
      itm.tagged_user_names.map((uname) => {
        // console.log(first)
        if (uname === getCookie('name')) {
          txt = txt.replace(`@${uname}`, `<div class="tagged-user">@${uname}</div>`)
        } else {
          txt = txt.replace(`@${uname}`, `<div class="text-highlight">@${uname}</div>`)
        }
        return null
      })
      return txt
    }
    return <p>{itm.comment}</p>
  }
  const handleSubmit = () => {
    if (!value) {
      return;
    }
    setSubmitting(true);
    props.onNewCommentAdd();
    const tagged_ids = []
    taggedUserList.map((user) => {
      if (value.includes(`@${user.name}`)) {
        tagged_ids.push(user.id)
      }
      return null
    })
    if (props.type === 'request_site' || props.type === 'request_pi') {
      dispatch(createCommentForApprovalAction({
        request_for: props.type === 'request_site' ? 'SITE' : 'PI',
        action_type: props.action_type,
        comment: value,
        tagged_ids,
        ...getCommentParams('createApi', getCommentType(props.type, sitePIExtraType), props),
      }));
    } else {
      dispatch(createCommentAction({
        type: getCommentType(props.type, sitePIExtraType),
        comment: value,
        is_lcor: props.is_lcor,
        tagged_ids,
        ...getCommentParams('createApi', getCommentType(props.type, sitePIExtraType), props),
      }));
    }
  };

  const handleChange = (e) => {
    if (e.target.value) {
      if (e.target.value.includes('@')) {
        setValue(e.target.value.trimStart());
        setIsUserListOpen(true)
        setSearchText(e.target.value.substring(e.target.value.lastIndexOf('@') + 1, e.target.value.length))
      } else {
        setIsUserListOpen(false)
        setSearchText('')
        setValue(e.target.value.trimStart());
      }
    } else {
      setValue('');
      setIsUserListOpen(false)
    }
  };

  const handleCancel = () => {
    setValue('');
    setIsUserListOpen(false)
    setSearchText('')
  };

  return (
    <div className="comment-sec">
      <div
        className="close-btn"
        role="presentation"
        onClick={() => {
          setValue('');
          props.onCloseClick()
        }}
      />
      <Loader loading={commentsData.loading || commentsForApprovalData.loading} error={commentsData.error || commentsForApprovalData.error}>
        {props.sitePIExtraType
          ? (
            <div className="specific-comment-dropdown">
              <div className="specific-comment-dropdown-label">Showing Comments:&nbsp;</div>
              <Select value={sitePIExtraType} style={{ width: 200 }} onChange={handleSitePIExtraTypeChange}>
                <Option value="specific">Specific to this project</Option>
                <Option value="generic">Generic comments</Option>
              </Select>
            </div>
          )
          : null}
        {comments?.length > 0
          ? (
            <>
              <CommentList
                {...props}
                onNewCommentAdd={() => props.onNewCommentAdd()}
                sitePIExtraType={sitePIExtraType}
                comments={comments}
                isUserListOpen={isUserListOpen}
                setIsUserListOpen={setIsUserListOpen}
                taggedUserList={taggedUserList}
                setTaggedUserList={setTaggedUserList}
                searchText={searchText}
                setSearchText={setSearchText}
                isCommentEdited={isCommentEdited}
                setIsCommentEdited={setIsCommentEdited}
              />
              <div className="cmt-separation" />
            </>
          )
          : null}
      </Loader>
      {!isCommentEdited && (
      <Comment
        avatar={
          (
            <Avatar
              style={{
                backgroundColor: '#8A0050',
              }}
            >
              {getCookie('name')[0]}
            </Avatar>
          )
        }
        content={
          (
            <>
              <Editor
                onChange={handleChange}
                onSubmit={handleSubmit}
                submitting={submitting}
                value={value}
                setValue={setValue}
                isUserListOpen={isUserListOpen}
                setIsUserListOpen={setIsUserListOpen}
                taggedUserList={taggedUserList}
                setTaggedUserList={setTaggedUserList}
                searchText={searchText}
                setSearchText={setSearchText}
                onCancel={handleCancel}
              />
            </>
            )
        }
      />
      )}
    </div>
  );
};

const CommentComponent = (props) => {
  const dispatch = useDispatch();
  const [isPopoverVisible, setIsPopoverVisible] = useState(props.showPopover || false);
  const [resetCommentsFlag, setResetCommentsFlag] = useState(false);
  const [tempCount, setTempCount] = useState(0);
  const [showCount, setShowCount] = useState(0);

  useEffect(() => {
    setIsPopoverVisible(props.showPopover);
  }, [props.showPopover])

  useEffect(() => {
    return () => {
      setTempCount(0);
    }
  }, [])

  useEffect(() => {
    setShowCount(parseInt(props.count, 10) + tempCount);
  }, [props.count, tempCount])

  useEffect(() => {
    if (isPopoverVisible) {
      dispatch(ListUsersAction())
      if (props.type === 'request_site' || props.type === 'request_pi') {
        dispatch(getCommentsForApprovalAction({
          request_for: props.type === 'request_site' ? 'SITE' : 'PI',
          ...getCommentParams('getApi', getCommentType(props.type, props.sitePIExtraType), props),
        }));
      } else if (props.from !== 'allPi') {
        dispatch(getCommentsAction({
          type: getCommentType(props.type, props.sitePIExtraType),
          ...getCommentParams('getApi', getCommentType(props.type, props.sitePIExtraType), props),
        }));
      }
      if (props.countType && (props.type === 'projects' || props.type === 'analysis' || props.type === 'queries')) {
        dispatch(getCommentsCountsAction({
          type: props.countType,
          ...getCommentParams('getCountApi', props.countType, props),
        }))
      }
    } else {
      setIsPopoverVisible(false);
      setResetCommentsFlag(true);
      dispatch(getCommentsResetAction());
      dispatch(getCommentsForApprovalResetAction());
      dispatch(getCommentsCountsResetAction());
    }
  }, [isPopoverVisible]);

  const onNewCommentAdd = () => {
    setTempCount(tempCount + 1);
  }

  return (
    <>
      {!props.hideMe
        ? (
          <Popover
            placement="left"
            title=""
            open={isPopoverVisible}
            content={<CommentEditor {...props} resetComments={resetCommentsFlag} onCloseClick={() => { setIsPopoverVisible(false); props.setIsCommentNeeded({ isNeeded: undefined, isProvied: undefined }) }} onNewCommentAdd={onNewCommentAdd} setIsPopoverVisible={setIsPopoverVisible} />}
            trigger="click"
            onOpenChange={(visible) => {
              setIsPopoverVisible(visible);
              if (!visible && props.onCommentClose) {
                props.onCommentClose();
              }
            }}
            destroyTooltipOnHide
          >
            <Button
              className="comments-btn"
              icon={(
                <div className={`comment-w-count ${props.count && (showCount === 0) ? 'fade-in' : ''}`}>
                  <Tooltip title="Comments">
                    <div className="comments-img" />
                  </Tooltip>
                  {props.count
                    ? (
                      <span className="count">
                        {showCount}
                      </span>
                    )
                    : null}
                </div>
              )}
            />
          </Popover>
        )
        : null}
    </>
  );
};

export default CommentComponent;
